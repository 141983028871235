<template>
	<ExportReportDinamic />
</template>
<script>

import ExportReportDinamic from '@/components/action-plan-dinamics/export-report-action-plan/index.vue'

export default {
	name: 'ExportReportDinamicPage',
	components: { ExportReportDinamic },
};
</script>
